import React from 'react';
import Tag from 'components/tag';
import './style.less';

export const dictionary = [
    'VIP',
    '包裹',
    '播放',
    '程序',
    '电脑',
    '定位',
    '对话',
    '二维码',
    '更多',
    '购物',
    '关心',
    '观看',
    '记录',
    '客服',
    '聊天',
    '皮肤',
    '钱包',
    '日期',
    '荣誉',
    '上传',
    '发送',
    '设置',
    '手机',
    '锁',
    '通话',
    '问题',
    '物流',
    '下载',
    '接收',
    '消费',
    '小程序',
    '修理',
    '语音',
];
export class FaqClassifyIcon extends React.Component {
    get index() {
        const { setting } = this.props;
        let index = dictionary.indexOf(setting.icon);

        if (!setting.icon) {
            index = 'default';
        } else {
            index += 1;
        }
        return index;
    }

    render() {
        return (
            <Tag.div className={`faq_classify_icon icon_${this.index}`}></Tag.div>
        );
    }
}