import React, { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { TcIconFaqHot, TcIconNext, TcIconNextThin, IconEmtydata } from 'components/IconSystem';
import { FaqClassifyIcon } from 'components/faq-classify-icon';
import { href_faqs_more, href_faqs_detail, href_faqs_category, href_faqs_list } from 'components/href-helper';
import { giveLikeNumber } from 'components/util';
import { Loading } from '@tencent/ten-design-react';
import { debounce } from 'lodash-es';
import { LoadMoreHook } from 'components/load-more/load-more-hook';
import more from './more.png';

import './style.less';

const ComProblem = (props) => {
    let hasFaqClassify = Boolean(props.faqClassifyList.length);

    if (props.faqClassifyList.length === 1 && props.faqClassifyList[0].title === '默认分类') {
        hasFaqClassify = false;
    }

    return (
        <div className="com_problem">
            {props.isPc ? (
                <PcClassifyList {...props} />
            ) : (
                <OrdinaryFaqList {...props} hasFaqClassify={hasFaqClassify} />
            )}
        </div>
    );
};

const OrdinaryFaqList = (props) => {
    const { list, hasFaqClassify } = props;
    const getItemHref = (item) => {
        if (item.child_info.type === 'category') {
            return href_faqs_category(item.id);
        }
        return href_faqs_list(item.id);
    };
    return (
        <>
            <div className="topic">
                <h3 className="topic_h">{props.title}</h3>
                {props.faqClassifyList.length <= 1 && (
                    <a target={props.target} className="topic_link" href={href_faqs_more()}>
                        全部
                    </a>
                )}
            </div>
            <div className={classNames('pro_list', { faq_classify: props.faqClassifyList.length })}>
                {!hasFaqClassify ? (
                    <ul>
                        {list.map((item, index) => (
                            <li className="item" key={item.id}>
                                {Boolean(item.solved_count) && (
                                    <span className="solved_num">{giveLikeNumber(item.solved_count)} 有用</span>
                                )}

                                <a target={props.target} href={href_faqs_detail(item.faq_id)}>
                                    <span className="item_font">{item.title}</span>
                                    {item.is_hot && <TcIconFaqHot />}
                                </a>
                            </li>
                        ))}
                        <a className="see_more" href={href_faqs_more()}>
                            查看更多 {`>`}
                        </a>
                    </ul>
                ) : (
                    <ul className="faq_classify_list" style={{ width: props.faqClassifyWidth }}>
                        {props.faqClassifyList.map((item, i) => {
                            if (item.code === 'default_node' && item.title === '') {
                                item.title = '默认分类';
                            }
                            return (
                                <li className="item" key={i}>
                                    <a target={props.target} href={getItemHref(item)}>
                                        <FaqClassifyIcon setting={item.setting} />
                                        <span className="item_font">{item.title}</span>
                                        <TcIconNext />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </>
    );
};

const PcClassifyList = (props) => {
    const { faqClassifyList, nextFaqClassifyList, classifyFaqList: faqList } = props;
    const titleEl = useRef([]);
    const listRef = useRef(null);
    const childListRef = useRef(null);
    const activeTitle = useRef(null);
    const [clickId, setClickId] = useState(faqClassifyList[0]?.id || null);
    const [nextClassifyId, setNextClassifyId] = useState(null);
    const [moveL, setMoveL] = useState(24);
    const [showSwitchBtn, setShowSwitchBtn] = useState(false);
    const [shadow, setShadow] = useState(true);
    const [showChildMore, setShowChildMore] = useState(false);
    const onlyDefaultFaq = faqClassifyList.length === 1 && faqClassifyList[0].code === 'default_node';

    const handleGetFaqList = (classifyId) => {
        if (!classifyId) return;
        props.getNextFaqClassify(classifyId);
    };

    const handleGetFaqClassify = (clickId) => {
        let i = faqClassifyList.findIndex((item) => item.id === clickId);
        props.resetFaqList();
        props.resetNextFaqClassifyList();

        // 如果不是分类，那就直接获取问题列表
        if (
            faqClassifyList[i].child_info.length === 0 ||
            (faqClassifyList[i].child_info.type && faqClassifyList[i].child_info.type !== 'category')
        ) {
            handleGetFaqList(clickId);
            return;
        }

        props.getNextFaqClassify(clickId, 'getCategory');
    };

    const FaqClassifyDebounced = useRef(debounce((clickId) => handleGetFaqClassify(clickId), 300));
    const FaqListDebounced = useRef(debounce((nextClassifyId) => handleGetFaqList(nextClassifyId), 300));

    // 防抖获取问题分类
    useEffect(() => FaqClassifyDebounced.current(clickId), [clickId]);

    // 防抖获取问题列表
    useEffect(() => FaqListDebounced.current(nextClassifyId), [nextClassifyId]);

    // 初始设置选中的样式
    useEffect(() => {
        let index = faqClassifyList.findIndex((item) => item.id === clickId);
        if (index >= 0 && !onlyDefaultFaq) {
            activeTitle.current.style.width = `${titleEl.current[index].offsetWidth}px`;
            activeTitle.current.style.left = `${titleEl.current[index].offsetLeft}px`;
        }
    }, [clickId, faqClassifyList, onlyDefaultFaq]);

    // 在 nextFaqClassifyList 发生变化后获取问题
    useEffect(() => {
        if (nextFaqClassifyList.length === 0) return;

        const nextId = nextFaqClassifyList[0]?.id;
        if (nextId) setNextClassifyId(nextId);

        if (childListRef.current.childNodes.length === 0) return;

        let w = 0;
        childListRef.current.childNodes.forEach((ele) => {
            let style = window.getComputedStyle(ele);
            let marginRight = parseInt(style.getPropertyValue('margin-right'), 10);
            w += ele.clientWidth + marginRight;
        });
        if (w > childListRef.current.offsetWidth) {
            setShowChildMore(true);
        } else {
            setShowChildMore(false);
        }
    }, [nextFaqClassifyList]);

    useEffect(() => {
        if (titleEl.current.length === props.faqClassifyList.length) {
            let w = 0;
            let containerW = listRef.current.offsetWidth;
            titleEl.current.forEach(ele => { w += (ele.clientWidth + 36) });

            // 前进后退按钮的显示与否
            if (w > containerW) setShowSwitchBtn(true);
        }
    }, [props.faqClassifyList.length, titleEl]);

    // 点击标题栏左右箭头的事件
    const onChangeClickId = (type) => {
        if (props.faqList_Loading) return;

        let index = 0;
        let loading = true;
        faqClassifyList.forEach((item, i) => {
            if (item.id === clickId) index = i;
        });

        if (type === 'next' && index < faqClassifyList.length - 1) {
            index += 1;
        } else if (type === 'prev' && index > 0) {
            index -= 1;
        } else {
            loading = false;
        }

        // 标题内容超出 ul 宽度时的小阴影
        index === faqClassifyList.length - 1 ? setShadow(false) : setShadow(true);

        const offsetW = titleEl.current[index].offsetWidth;
        const offsetL = titleEl.current[index].offsetLeft + offsetW;
        const titleListW = listRef.current.offsetWidth;

        activeTitle.current.style.width = `${offsetW}px`;
        activeTitle.current.style.left = `${titleEl.current[index].offsetLeft}px`;

        if (offsetL + 24 > titleListW) {
            setMoveL(titleListW - offsetL);
        } else {
            setMoveL(24);
        }

        if (loading) props.setFaqListLoading();
        setClickId(faqClassifyList[index].id);
        setNextClassifyId(null);
    };

    const toggleClickId = (id, index) => {
        if (props.faqList_Loading) return;

        if (clickId === id) return;

        const offsetW = titleEl.current[index].offsetWidth;
        const offsetL = titleEl.current[index].offsetLeft + offsetW;
        const titleListW = listRef.current.offsetWidth;

        activeTitle.current.style.width = `${offsetW}px`;
        activeTitle.current.style.left = `${titleEl.current[index].offsetLeft}px`;

        if (offsetL + 24 > titleListW) {
            setMoveL(titleListW - offsetL);
        } else {
            setMoveL(24);
        }

        props.setFaqListLoading();
        setClickId(id);
        setNextClassifyId(null);
    };

    return (
        <div className="classify_body">
            <div className="title_tab">
                <div className="title_list" ref={listRef}>
                    <ul style={{ left: `${moveL}px` }}>
                        {faqClassifyList.map((item, i) => {
                            return (
                                <li
                                    className={classNames({ 'li-active': item.id === clickId })}
                                    ref={(li) => {
                                        titleEl.current[i] = li;
                                    }}
                                    key={item.id}
                                    onClick={() => toggleClickId(item.id, i)}
                                >
                                    {onlyDefaultFaq ? '常见问题' : item.title}
                                </li>
                            );
                        })}
                        <div ref={activeTitle} className="is-active"></div>
                    </ul>
                </div>
                {showSwitchBtn && (
                    <div className={classNames('switch_btns', { shadow })}>
                        <div className="prev" role="button" tabIndex="0" onClick={() => onChangeClickId('prev')}>
                            <TcIconNextThin />
                        </div>
                        <div className="next" role="button" tabIndex="-1" onClick={() => onChangeClickId('next')}>
                            <TcIconNextThin />
                        </div>
                    </div>
                )}
            </div>

            <div className="classify_content">
                {Boolean(nextFaqClassifyList.length) && (
                    <div className="pc_next_faq_classify">
                        <ul ref={childListRef}>
                            {nextFaqClassifyList.map((item) => {
                                return (
                                    <li
                                        key={item.id}
                                        className={classNames({ active: item.id === nextClassifyId })}
                                        onClick={() => setNextClassifyId(item.id)}
                                    >
                                        {`${item.title}`}
                                    </li>
                                );
                            })}
                        </ul>
                        {showChildMore && (
                            <a
                                href={`${href_faqs_more()}#parentId=${clickId}?#classifyId=${nextClassifyId}`}
                                className="see_more"
                            >
                                更多
                            </a>
                        )}
                    </div>
                )}

                <div className={classNames('pc_home_pro_list pro_list', { hasMore: Boolean(faqList.length >= 8) })}>
                    {faqList.length ? (
                        <ul>
                            {faqList.map((item) => {
                                return (
                                    <li key={item.id} className="item">
                                        <a target={props.target} href={`${href_faqs_detail(item.faq_id)}`}>
                                            {Boolean(item.solved_count) && (
                                                <span className="solved_num">
                                                    {giveLikeNumber(item.solved_count)} 有用
                                                </span>
                                            )}
                                            <span className="item_font">{`${item.title}`}</span>
                                            {item.faq_info.is_hot && <TcIconFaqHot />}
                                        </a>
                                    </li>
                                );
                            })}
                            {Boolean(faqList.length >= 8) && (
                                <a
                                    href={`${href_faqs_more()}#parentId=${clickId}?#classifyId=${nextClassifyId}`}
                                    className="see_more"
                                >
                                    查看更多
                                    <img src={more} alt="" />
                                </a>
                            )}
                        </ul>
                    ) : (
                        <div className="post_empty">
                            <span className="font">该分类下没有内容</span>
                        </div>
                    )}

                    {props.faqList_Loading && (
                        <div className="faqList-loading">
                            <LoadMoreHook />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ComProblem.defaultProps = {
    list: [],
    title: '常见问题',
    type: '',
    faqClassifyList: [],
    nextFaqClassifyList: [],
    isPc: false,
    faqClassifyWidth: '100%',
};

export { ComProblem };